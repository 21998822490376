import { useState, useEffect } from "react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";

interface PasswordStrengthProgressProps {
  password: string;
}

const PasswordStrengthProgress: React.FC<PasswordStrengthProgressProps> = ({
  password,
}) => {
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [requirements, setRequirements] = useState({
    minLength: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumbers: false,
    hasSpecialChars: false,
  });

  const validatePassword = (password: string) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    let strength = 0;
    if (password.length >= minLength) strength += 1;
    if (hasUpperCase) strength += 1;
    if (hasLowerCase) strength += 1;
    if (hasNumbers) strength += 1;
    if (hasSpecialChars) strength += 1;

    setPasswordStrength(strength);
    setRequirements({
      minLength: password.length >= minLength,
      hasUpperCase,
      hasLowerCase,
      hasNumbers,
      hasSpecialChars,
    });
  };

  const getPasswordStrengthLabel = (strength: number) => {
    switch (strength) {
      case 1:
      case 2:
        return "Weak";
      case 3:
        return "Fair";
      case 4:
        return "Good";
      case 5:
        return "Strong";
      default:
        return "";
    }
  };

  const getPasswordStrengthColor = (strength: number) => {
    switch (strength) {
      case 1:
      case 2:
        return "red";
      case 3:
        return "yellow";
      case 4:
        return "indigo";
      case 5:
        return "green";
      default:
        return "gray";
    }
  };

  useEffect(() => {
    validatePassword(password);
  }, [password]);

  return (
    <>
      {password.length > 0 && (
        <div>
          <div className="relative mt-2 h-2 rounded bg-gray-200">
            <div
              className={`h-full rounded bg-${getPasswordStrengthColor(passwordStrength)}-500 transition-all duration-300`}
              style={{ width: `${(passwordStrength / 5) * 100}%` }}
            ></div>
          </div>
          <div className="flex justify-end">
            <p
              className={`mt-1 text-${getPasswordStrengthColor(passwordStrength)}-500`}
            >
              {getPasswordStrengthLabel(passwordStrength)}
            </p>
          </div>
          {passwordStrength !== 5 && (
            <ul className="mt-2 space-y-2">
              <li
                className={`flex items-center ${requirements.minLength ? "text-green-500" : "text-gray-500"}`}
              >
                {requirements.minLength ? (
                  <CheckCircleIcon className="mr-2 size-6 text-green-500" />
                ) : (
                  <XCircleIcon className="mr-2 size-6 text-gray-500" />
                )}
                <span>At least 8 characters</span>
              </li>
              <li
                className={`flex items-center ${requirements.hasUpperCase ? "text-green-500" : "text-gray-500"}`}
              >
                {requirements.hasUpperCase ? (
                  <CheckCircleIcon className="mr-2 size-6 text-green-500" />
                ) : (
                  <XCircleIcon className="mr-2 size-6 text-gray-500" />
                )}
                <span>At least one uppercase letter</span>
              </li>
              <li
                className={`flex items-center ${requirements.hasLowerCase ? "text-green-500" : "text-gray-500"}`}
              >
                {requirements.hasLowerCase ? (
                  <CheckCircleIcon className="mr-2 size-6 text-green-500" />
                ) : (
                  <XCircleIcon className="mr-2 size-6 text-gray-500" />
                )}
                <span>At least one lowercase letter</span>
              </li>
              <li
                className={`flex items-center ${requirements.hasNumbers ? "text-green-500" : "text-gray-500"}`}
              >
                {requirements.hasNumbers ? (
                  <CheckCircleIcon className="mr-2 size-6 text-green-500" />
                ) : (
                  <XCircleIcon className="mr-2 size-6 text-gray-500" />
                )}
                <span>At least one number</span>
              </li>
              <li
                className={`flex items-center ${requirements.hasSpecialChars ? "text-green-500" : "text-gray-500"}`}
              >
                {requirements.hasSpecialChars ? (
                  <CheckCircleIcon className="mr-2 size-6 text-green-500" />
                ) : (
                  <XCircleIcon className="mr-2 size-6 text-gray-500" />
                )}
                <span>At least one special character</span>
              </li>
            </ul>
          )}
        </div>
      )}
    </>
  );
};

export default PasswordStrengthProgress;
