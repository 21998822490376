"use client";

import { useState, useEffect } from "react";
import { Field, Label } from "@/app/_components/ui/fieldset";
import {
  Listbox,
  ListboxLabel,
  ListboxOption,
} from "@/app/_components/ui/listbox";

type DropdownData = {
  id: number | string;
  name: string;
  value?: string;
  disabled?: boolean;
};

type DropdownProps = {
  data: DropdownData[];
  name?: string;
  label?: string;
  selected?: DropdownData;
  onChange: (id: number | string) => void; // Updated type to include string
};

export default function DropdownField({
  data,
  label,
  name,
  selected: selectedProp,
  onChange,
}: DropdownProps) {
  const [selected, setSelected] = useState<DropdownData | null>(
    selectedProp ? selectedProp : null,
  );

  useEffect(() => {
    if (selectedProp) {
      setSelected(selectedProp);
    }
  }, [selectedProp]);

  return (
    <Field>
      <Label>{label}</Label>
      <Listbox
        placeholder={"Select " + label}
        value={selected}
        onChange={(value: DropdownData | null) => {
          setSelected(value);
          if (value) {
            onChange(value.id);
          }
        }}
        name={name}
      >
        {data
          .sort((a, b) => (a.name ?? "").localeCompare(b.name ?? ""))
          .map((item) => (
            <ListboxOption key={item.id} value={item}>
              <ListboxLabel>{item.name}</ListboxLabel>
            </ListboxOption>
          ))}
      </Listbox>
    </Field>
  );
}
