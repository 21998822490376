import {
  ExclamationTriangleIcon,
  XCircleIcon,
  CheckCircleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import Link from "next/link";

interface NoticeProps {
  type: "warning" | "error" | "success" | "info" | "uploadSuccess" | "";
  title: string;
  message: string;
  listItems?: string[];
  href?: string;
  hrefText?: string;
}

const iconStyles = "h-5 w-5";
const iconColorClasses = {
  warning: "text-yellow-400",
  error: "text-red-400",
  success: "text-green-400",
  info: "text-blue-400",
  uploadSuccess: "text-green-500",
};
const bgColorClasses = {
  warning: "bg-yellow-50",
  error: "bg-red-50",
  success: "bg-green-50",
  info: "bg-blue-50",
  uploadSuccess: "bg-green-50",
};
const textColorClasses = {
  warning: "text-yellow-700",
  error: "text-red-700",
  success: "text-green-700",
  info: "text-blue-700",
  uploadSuccess: "text-green-800",
};

export default function Notice({
  type,
  title,
  message,
  listItems,
  href,
  hrefText,
}: NoticeProps) {
  if (!type) return null;

  const Icon = {
    warning: ExclamationTriangleIcon,
    error: XCircleIcon,
    success: CheckCircleIcon,
    info: InformationCircleIcon,
    uploadSuccess: CheckCircleIcon,
  }[type];

  return (
    <div className={`rounded-md ${bgColorClasses[type]} p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon
            className={`${iconStyles} ${iconColorClasses[type]}`}
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className={`text-sm font-medium ${textColorClasses[type]}`}>
            {title}
          </h3>
          <div className={`mt-2 text-sm ${textColorClasses[type]}`}>
            <p>{message}</p>
            {listItems && (
              <ul role="list" className="my-2 list-disc space-y-1 pl-5">
                {listItems.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            )}
            {href && (
              <Link
                href={href}
                className={`font-medium underline hover:text-${
                  iconColorClasses[type].split("-")[1]
                }-600`}
              >
                {hrefText}
              </Link>
            )}
          </div>
        </div>
        {type === "uploadSuccess" && (
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                className="inline-flex rounded-md bg-green-50 p-1.5 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
